import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Header from 'components/shared/header';
import Footer from 'components/shared/footer';
import Freshchat from 'components/shared/freshchat';
import { ParallaxProvider } from 'react-scroll-parallax';
import styles from './layout-main.module.scss';

const LayoutMain = ({ children }) => (
  <ParallaxProvider>
    <Header />
    <main>{children}</main>
    <Footer />
    <Freshchat />
  </ParallaxProvider>
);

LayoutMain.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutMain;
