import { useState, useEffect } from 'react';

const isBrowser =
  typeof window !== 'undefined' && typeof window.document !== 'undefined';
const useScript = ({ src, ...attributes }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    if (!isBrowser) return;
    const scriptEl = document.createElement('script');
    scriptEl.setAttribute('src', src);
    Object.keys(attributes).forEach(key => {
      if (scriptEl[key] === undefined) {
        scriptEl.setAttribute(key, attributes[key]);
      } else {
        scriptEl[key] = attributes[key];
      }
    });
    const handleLoad = () => {
      setLoading(false);
    };
    const handleError = err => {
      setError(err);
    };
    scriptEl.addEventListener('load', handleLoad);
    scriptEl.addEventListener('error', handleError);
    document.body.appendChild(scriptEl);
    // eslint-disable-next-line
    return () => {
      scriptEl.removeEventListener('load', handleLoad);
      scriptEl.removeEventListener('error', handleError);
    };
    // we need to ignore the attributes as they're a new object per call, so we'd never skip an effect call
  }, [src]);
  return [loading, error];
};
export default useScript;
