import React from 'react';
import { Link } from 'gatsby';
import styles from './footer.module.scss';
import Logo from './svg/logo.inline.svg';

const Footer = ({ logoWhite }) => (
  <footer className={styles.footer}>
    <div className="container">
      <div className="columns is-multiline">
        <div className="column is-12 is-4-tablet is-5-desktop ">
          <Link to="/">
            <Logo
              className={`${styles.logo} ${logoWhite && styles.logo_white}`}
            />
          </Link>
        </div>
        <div
          className={`column is-12 is-8-tablet is-7-desktop ${styles.menus}`}
        >
          <div className={styles.menu}>
            <div className={styles.menuTitle}>Social</div>
            <div className={styles.menuItemWrapper}>
              <a className="link white" href="https://www.instagram.com/bamleads">
                Instagram
              </a>
            </div>
            <div className={styles.menuItemWrapper}>
              <a className="link white" href="https://www.facebook.com/Bam-Leads-109377584122029">
                Facebook
              </a>
            </div>
          </div>
          <div className={styles.menu}>
            <div className={styles.menuTitle}>Legal</div>
            <div className={styles.menuItemWrapper}>
              <Link className="link white" to="/privacy-policy">
                Privacy Policy
              </Link>
            </div>
            <div className={styles.menuItemWrapper}>
              <Link className="link white" to="/terms-of-service">
                Terms of service
              </Link>
            </div>
          </div>
          <div className={styles.menu}>
            <div className={styles.menuTitle}>Contact us</div>
            <div className={styles.menuItemWrapper}>
              <a className="link white" href="mailto:sales@bamleads.com">
                sales@bamleads.com
              </a>
            </div>
            <div className={styles.menuItemWrapper}>
              <a className="link white" href="tel:8555818292">
                855.581.8292
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.info}>
        <div className="columns">
          <div className="column">
           
            <a
              className="link green sm bold"
              href=""
              target="_blank"
            >
              
            </a>
          </div>
          <div className="column is-narrow">
            Bam Leads © 2020 - All rights reserved
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
