import React, { useEffect } from 'react';
import useScript from 'utils/use-script';

const Freshchat = props => {
  const [scriptLoading] = useScript({
    src: process.env.GATSBY_FRESHCHAT_API_URL,
    async: true,
  });

  const initFreshChat = () => {
    window.fcWidget.init({
      token: process.env.GATSBY_FRESHCHAT_TOKEN,
      host: process.env.GATSBY_FRESHCHAT_HOST,
    });
  };

  useEffect(() => {
    if (!scriptLoading) {
      initFreshChat();
    }
  }, [scriptLoading]);

  return null;
};

export default Freshchat;
