import React from 'react';
import styles from './header-menu.module.scss';

const HeaderMenu = ({ logoWhite }) => (
  <nav className={styles.wrapper}>
    <a
      className={`link ${logoWhite && 'white'}`}
      href="https://app.bamleads.com/users/sign_in"
    >
      Login
    </a>
    <a className="button primary" href="https://app.bamleads.com/users/sign_up">
      Sign up free
    </a>
  </nav>
);

export default HeaderMenu;
