import React from 'react';
import styles from './header.module.scss';
import { Link } from 'gatsby';
import HeaderMenu from '../header-menu';
import Logo from './logo.inline.svg';

const Header = ({ logoWhite }) => (
  <header className={styles.wrapper}>
    <div className={`container ${styles.container}`}>
      <Link to="/" className={styles.logoContainer}>
        <Logo className={`${styles.logo} ${logoWhite && styles.logo_white}`} />
      </Link>
      <div className={styles.navigation}>
        <HeaderMenu logoWhite={logoWhite} />
      </div>
    </div>
  </header>
);

export default Header;
